const types = {
  403: {
    code: {
      left: 4,
      right: 3,
      full: 403
    },
    title: 'Oops! Không có quyền truy cập',
    desc: 'Xin lỗi bạn không có quyền truy cập trang này. Vui lòng liên hệ với quản trị viên để biết thêm chi tiết.',
    class: 'exception-403'
  },
  404: {
    code: {
      left: 4,
      right: 4,
      full: 404
    },
    title: 'Oops! Trang không tồn tại',
    desc: 'Xin lỗi, trang bạn vừa truy cập không tồn tại, vui lòng kiểm tra lại đường dẫn.',
    class: 'exception-404'
  },
  500: {
    code: {
      left: 5,
      right: 0,
      full: 400
    },
    title: 'Oops! Có lỗi xảy ra trên hệ thống',
    desc: 'Xin lỗi, đã có một lỗi tiềm ẩn xảy ra.',
    class: 'exception-500'
  }
}

export default types
